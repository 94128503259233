<template>
  <div class="vh-100">
    <div
      class="h-100 d-flex flex-column justify-content-between align-items-center p-5"
    >
      <h5 class="text-center public-title">
        <strong
          >{{ preference.additional_metadata.vms_public_welcome_message }}
        </strong>
      </h5>
    </div>
    <div class="public-image">
      <b-img
        fluid
        center
        :src="
          showImage(preference.additional_metadata.vms_public_branding_image)
        "
      ></b-img>
    </div>

    <div class="public-text">
      <router-link
        :to="{
          name: 'public-registration'
        }"
        v-slot="{ href, route, navigate }"
        custom
      >
        <b-button
          variant="primary"
          class="text-center"
          :href="href"
          @click="navigate"
          block
        >
          SELF CHECK-IN
        </b-button>
      </router-link>
      <div class="text-center">
        By proceeding, you agree to our
        <a target="_blank" :href="preference.tnc_metadata.tnc_url"
          ><u>Terms & Conditions</u></a
        >
        and confirm that you have understood our
        <a target="_blank" :href="preference.tnc_metadata.policy_url"
          ><u>Privacy Policy</u></a
        >.
      </div>
      <div
        class="text-center"
        v-if="preference.additional_metadata.vms_pdpa_setting == 'save_partial'"
      >
        You also agree to us collecting and storing your personal information
        according to regulations outlined by the PDPA.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: function () {
    return {
      preference: {
        visit_end_time: "00:00:00",
        visit_start_time: "00:00:00",
        tolerance_time_in: 10,
        forms_compulsory_fields: {},
        additional_metadata: {
          vms_public_branding_image: null,
          vms_public_welcome_message: "-",
          vms_preferences_default_visitor_type: "NORMAL_VISIT"
        },
        created: "2022-06-02T09:38:20.503000+00:00",
        tolerance_time_out: 10,
        id: "c76a900e-51a6-4f0e-ac86-cb8883301462",
        forms: {},
        tnc_metadata: {
          tnc_url: "https://www.google.com/",
          policy_url: "https://www.google.com/"
        },
        modified: "2022-06-10T07:53:02.362098+00:00"
      }
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    })
  },
  mounted: function () {
    this.getPublicVisitPreference();
  },
  methods: {
    showImage: function (img) {
      return `data:image/jpeg;base64,${img}`;
    },

    getPublicVisitPreference() {
      let API_URL = this.getAPIServerURL + "/public/vms/visit-preference/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.preference = data;
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.public-title {
  position: absolute;
  top: 10%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}
.public-image {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}

.public-text {
  position: absolute;
  top: 90%;
  width: 100%;
  padding: 0rem 2rem;
  overflow: hidden;
}
</style>
